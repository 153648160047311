import { Box, Typography } from '@mui/material';
import * as Roboto300 from '@fontsource/roboto/300.css';
import SlotComponent from './slot-component/SlotComponent';

function JoinUsPage (props) {
    return (
         <Box sx={{
            objectPosition: "center top",
            minHeight: "100vh",
            overflowY: "scroll",
            background: "#000 url('/imgs/join-the-team-background-large.png')",
            backgroundSize: "cover",
            position: "relative",
            zIndex: 0,
            display: "flex",
            flexDirection: "column"
        }}>
            <Box sx={{
                objectPosition: "center",
                minHeight: "calc(50vh - 88px)",
                background: "#000 url('/imgs/JoinUsPageBanner.jpg') center center",
                backgroundSize: "cover",
                position: "relative"
            }}/>
            <Box sx={{
                minHeight: "calc(50vh - 22px)",
                background: "white",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "1rem",
                flexGrow: 1
            }}>
                <Typography sx={{font: Roboto300, fontSize: "3rem", cursor: "pointer", textAlign: "center"}}>Join Us</Typography>
                <Box sx={{
                    display: "flex",
                    marginTop: "4rem",
                    flexDirection: "row",
                    maxWidth: "894px",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    alignContent: "flex-start",
                    flexGrow: 1
                }}>
                    <SlotComponent slotName={"Trial Week"} slotDescription={"Test"} slotDuration={"Duration Varies"} slotPrice={"Price Free"}/>
                    <SlotComponent slotName={"Classes"} slotDescription={"Mondays & Wednesdays 17:30 - 19:30"} slotDuration={"2 hr"} slotPrice={""} isLast/>
                </Box>
            </Box>
        </Box>
    )
}

export default JoinUsPage;