import { Box, Typography } from '@mui/material';
import * as Roboto300 from '@fontsource/roboto/300.css';
import TeamMemberProfile from './TeamMemberProfile/TeamMemberProfile';

function MeetOurTeamSection (props) {
    return (
        <Box sx={{
            minHeight: "100vh",
            backgroundColor: "transparent",
            position: "relative",
            paddingBottom: "2rem",
            flexGrow: 1
        }}>
            <Box sx={{
                minHeight: "calc(40vh - 88px)",
                backgroundColor: "black",
                position: "absolute",
                width: "100vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                top: "2rem",
                zIndex: -1
            }}>
                <Typography sx={{font: Roboto300, fontSize: "2.4rem", color: "rgb(255, 195, 20)", fontWeight: "bold", paddingTop: "3rem"}}>MEET OUR CADRE</Typography>
            </Box>
            <Box sx={{
                zIndex: 2,
                position: "absolute",
                top: "10rem",
                display: "flex",
                justifyContent: "center",
                width: "100vw"
            }}>
                <Box sx={{
                    // display: "flex",
                    position: "relative",
                    display: "grid",
                    flexDirection: "row",
                    gridTemplateColumns: "repeat(auto-fill, minmax(0, min(900px/1, max(287px, 900px/3))))",
                    justifyContent: "center",
                    maxHeight: "708px",
                    overflowY: "scroll",
                    // gap: 4,
                    flexGrow: 1,
                    flexShrink: 1,
                    maxWidth: "1000px",
                    overflow: "hidden",
                    gap: "1rem"
                }}>
                <TeamMemberProfile/>
                <TeamMemberProfile/>
                <TeamMemberProfile/>
                </Box>
            </Box>
        </Box>
    )
}

export default MeetOurTeamSection;