import { Box, Button } from '@mui/material';
import { useNavigate  } from "react-router-dom";

function InitialJoinSection (props) {
    let navigate = useNavigate ();
    return (
        <Box sx={{
            objectPosition: "center top",
            minHeight: "calc(100vh - 88px)",
            background: "#000 url('/imgs/team-background-large.jpg') center bottom",
            backgroundSize: "cover",
            position: "relative"
        }}>
            <Button variant="contained" onClick={() => {navigate("/join-us")}} disableElevation sx={{ padding: "3px 48px", position: "absolute", bottom: 16, left: "calc(50% - 170px)", backgroundColor: "rgb(255, 195, 20)", border: "6px solid black", color: "black", fontSize: "1.2rem", "&:hover": {backgroundColor: "white"}}}>
                JOIN THE TEAM
            </Button>
        </Box>
    )
}

export default InitialJoinSection;