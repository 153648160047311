import { Typography } from '@mui/material';
import React from 'react'
import { useNavigate  } from "react-router-dom";
import * as Roboto300 from '@fontsource/roboto/300.css';
import '@fontsource/roboto/500.css';

function NavButton (props) {
    const { buttonText, onThisPage, linkTo, onClick } = props;
    let navigate = useNavigate ();

    return (
        <Typography onClick={() => onClick ? onClick() : navigate(linkTo)} sx={{font: Roboto300, fontSize: "1rem", cursor: "pointer", transition: "all ease-in-out .25s",
        color: onThisPage ? "rgb(255, 195, 20)" : "black",
        "&:hover":{
            color: "rgb(255, 195, 20)"
        }}}>
            {buttonText}
        </Typography>
    )
}

export default NavButton;