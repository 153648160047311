import { Box, Typography } from '@mui/material';
import * as Roboto300 from '@fontsource/roboto/300.css';

function TeamMemberProfile (props) {
    return (
        <Box sx={{
            backgroundColor: "white",
            minWidth: "287px", 
            maxWidth: "287px",
            display: "flex",
            flexDirection: "column",
            border: "3px solid black",
            flexBasis: "20px",
            WebkitFlexShrink: "1",
            WebkitFlexGrow: "1",
            flexGrow: "1",
            flexShrink: "1",
        }}>
            <img alt="cadre-profile" src={"/imgs/staff-temp/scott.png"} width={"287px"}/>
            <Box sx={{
                backgroundColor: "rgb(255, 195, 20)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderTop: "3px solid black",
                borderBottom: "3px solid black",
                paddingTop: ".5rem",
                paddingBottom: ".5rem"
            }}>
                <Typography sx={{font: Roboto300, fontSize: "1.2rem", color: "black"}}>Scott McTott</Typography>
                <Typography sx={{font: Roboto300, fontSize: "1.2rem", color: "black"}}>The Level 100 Boss</Typography>
            </Box>
            <Box sx={{
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "1rem",
                flexWrap: "wrap",
                textAlign: "center"
            }}>
                <Typography sx={{font: Roboto300, fontSize: "1.2rem", color: "black"}}>Scott Li is a 2nd Dan in ITF style Taekwon-do. He is ITF HQ Korea. Scott has been practicing Taekwon-do for 15 years now and is on the national team and has represented Ireland on multiple occasions. Scott is a certified ITFHQ umpire & referree.</Typography>
            </Box>
        </Box>
    )
}

export default TeamMemberProfile;