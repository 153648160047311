import { Box, Typography } from '@mui/material';
import * as Roboto300 from '@fontsource/roboto/300.css';

function AboutTKDSection (props) {
    return (
        <Box sx={{
            objectPosition: "center top",
            minHeight: "calc(100vh - 88px)",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap"
        }}>
            <Box sx={{
                minHeight: "calc(80vh - 88px)",
                backgroundColor: "white",
                position: "absolute",
                width: "100vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                top: "10.5vh",
                zIndex: -1
            }} />
            <Box  sx={{
                display: { xs: 'flex', md: 'grid' },
                gridTemplateColumns: "minmax(0, 645px) 1fr",
                gap: 1
            }}>
                <Box sx={{ minHeight: "min(665px, calc(80vh - 88px - 4rem))", marginTop: "5rem", backgroundColor: "rgb(255, 195, 20)", padding: "2rem", width: { xs: '100vw', md: "auto" }}}>
                    <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
                        <Typography sx={{font: Roboto300, fontSize: "2rem", color: "white"}}>ABOUT TAEKWON-DO</Typography>
                        <Typography sx={{font: Roboto300, fontSize: "2rem", color: "white", display: { xs: 'none', md: 'flex' }}}>태권도</Typography>
                    </Box>
                    <Typography sx={{font: Roboto300, fontSize: "1rem", color: "black", minHeight: 0, padding: "2rem"}}>Taekwon-Do is a Korean martial art characterized by punching and kicking techniques, with specialisation on head-height kicks, spinning jump kicks, and fast techniques. The literal translation for Taekwon-do is "kicking," "punching," and "the art or way of."


'Taekwon-do' was innitially created by a collaborative effort by representatives from the original nine Kwans of Korea, initially supervised by Choi Hong Hi who was the founding father of 'Taekwon-do' on the 11th of April 1955, which prior to that was called Gong Soo Do.

 

Taekwon-do is a martial art that first and foremost about self defense which one can see in sparring and patterns.</Typography>
                    <Box sx={{float: "right", padding: "4rem"}}>
                        <Typography sx={{font: Roboto300, fontSize: "1rem", textAlign: "center", color: "black", minHeight: 0}}><i>General Choi Hong Hi</i></Typography>
                        <Typography sx={{font: Roboto300, fontSize: "1rem", textAlign: "center", color: "black", minHeight: 0}}><i>Founding father </i></Typography>
                    </Box>
                </Box>
                <Box sx={{ minHeight: "min(665px, calc(80vh - 88px))", marginTop: "5rem", maxWidth: "535px", display: { xs: 'none', md: 'flex' }}}>
                    <img alt="ChoiHongHi" src="/imgs/ChoiHongHi.webp" style={{maxHeight: "calc(80vh - 88px)"}}/>
                </Box>
            </Box>
        </Box>
    )
}

export default AboutTKDSection;