import { Box, Typography } from '@mui/material';
import * as Roboto300 from '@fontsource/roboto/300.css';

function DocumentFile(props) {
    const { fileName, fileDescription, fileLink } = props;

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: { xs: 'center', md: "flex-start" },
            flexGrow: 1,
            flexBasis: { xs: 'auto', md: "894px" },
            maxHeight: "120px",
            paddingBottom: "2rem",
            width:  { xs: '100vw', md: "auto" }
        }}>
            <Box sx={{display: "flex", flexDirection: "column", maxWidth: "795px", flexBasis: { xs: '50vw', md: "795px" }}}>
                <Typography sx={{ font: Roboto300, fontSize: "2rem", marginRight: 'auto'  ,fontWeight: "bold" }}>{fileName}</Typography>
                <Typography sx={{ font: Roboto300, fontSize: "1rem", maxWidth: "795px",  display: { xs: 'none', md: 'flex' } }}>{fileDescription}</Typography>
            </Box>
            <Box sx={{ cursor: "pointer" }}>
                <img alt="pdf" src="/imgs/file-logos/pdf-logo.png" height={96} onClick={() => console.log(fileLink)}/>
            </Box>
        </Box>
    )
}

export default DocumentFile;