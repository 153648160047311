import { Box, Typography, Button } from '@mui/material';
import * as Roboto300 from '@fontsource/roboto/300.css';

function SlotComponent(props) {
    const { slotName, slotDescription, slotDuration, slotPrice, isLast } = props;

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "center",
            flexGrow: 1,
            flexBasis: { xs: 'auto', md: "894px" },
            maxHeight: "120px",
            paddingBottom: "2rem",
            paddingTop: "2rem",
            width:  { xs: '100vw' },
            borderTop: "1px solid rgb(255, 195, 20)",
            borderBottom: (isLast) ? "1px solid rgb(255, 195, 20)" : ""
            
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: {xs: "column", md: "row"},
                alignItems: "flex-start",
                justifyContent: { xs: 'center', md: "flex-start" }, 
                flexGrow: 1,
                paddingRight: "1.5rem"
            }}>
                <Box sx={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                    <Typography sx={{ font: Roboto300, fontSize: "1.2rem", marginRight: 'auto', marginBottom: "0.5rem" }}>{slotName}</Typography>
                    <Typography sx={{ font: Roboto300, fontSize: "1.2rem", maxWidth: `${795/2}px`}}>{slotDescription}</Typography>
                </Box>
                <Box sx={{display: "flex", flexDirection: "column", marginLeft: {xs: "none", md: "auto"}}}>
                    <Typography sx={{ font: Roboto300, fontSize: "1.2rem", marginRight: 'auto', marginBottom: "0.5rem" }}>{slotDuration}</Typography>
                    <Typography sx={{ font: Roboto300, fontSize: "1.2rem", maxWidth: `${795/2}px`}}>{slotPrice}</Typography>
                </Box>
            </Box>
            <Box>
                <Button sx={{borderRadius: 0, width:"96px", padding: ".2rem 4rem", backgroundColor: "rgb(255, 195, 20)", color: "black", fontSize: "1rem", fontWeight: "bold", "&:hover": {backgroundColor: "#FFE18A"}}}>Register</Button>
            </Box>
        </Box>
    )
}

export default SlotComponent;