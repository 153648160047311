import { Box } from '@mui/material';
import { default as InitialJoin } from './sections/initial-join/InitialJoinSection'
import AboutUsSection from './sections/about-us/AboutUsSection';
import MeetOurTeamSection from './sections/meet-our-team/MeetOurTeamSection';
import AboutTKDSection from './sections/about-tkd/AboutTKDSection';

function LandingPage (props) {
    return (
        <Box sx={{
            objectPosition: "center top",
            minHeight: "calc(100vh - 88px)",
            maxHeight: "100vh",
            overflowY: "scroll",
            background: "#000 url('/imgs/join-the-team-background-large.png')",
            backgroundSize: "cover",
            position: "relative",
            zIndex: 0,
            overflowX: "none"
        }}>
            <InitialJoin/>
            <AboutUsSection/>
            <MeetOurTeamSection/>
            <AboutTKDSection/>
        </Box>
    )
}

export default LandingPage;