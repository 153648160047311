import { createContext, useContext, useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import axios from "axios"
axios.defaults.withCredentials = true;
const supabase = createClient("https://grfyfnpwakjerexikpsb.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdyZnlmbnB3YWtqZXJleGlrcHNiIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTU2MDM2MzUsImV4cCI6MjAxMTE3OTYzNX0.Tza01A8175Lby8yHfvdajVG79T6O1XFmiiL1XM_wNRg");
const AuthContext = createContext()

function AuthProvider({ children }) {
    
    const [session, setSession] = useState(null)

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
        })

        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })

        return () => subscription.unsubscribe()
    }, [])

    async function signInWithEmail(email, password) {
        await supabase.auth.signInWithPassword({
            email: email,
            password: password,
        })
    }

    async function signOut () {
        await supabase.auth.signOut()
    }

    return (
        <AuthContext.Provider value={{ session, setSession, supabase, signInWithEmail, signOut }}>
            {children}
        </AuthContext.Provider>
    )
}

function useAuth() {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider')
    }

    return context;
}

export { AuthProvider, useAuth }