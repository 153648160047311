import { AppBar, Container, Toolbar, Box, Typography } from '@mui/material';
import { useLocation } from "react-router-dom";
import { useAuth } from '../Auth/authManager';
import React from 'react'
import '@fontsource/roboto/500.css';
import NavButton from './components/NavButton/NavButton';

const ROUTES = {
    HOME: "/",
    NEWS: "/news",
    JOIN_US: "/join-us",
    DOCUMENTS: "/documents",
    GALLERY: "/gallery",
    MEMBERS_AREA: "/members-area"
}

function NavBar (props) {
    const {session, signOut} = useAuth()
    const currentRoute = useLocation()
    
    return (
        <AppBar position='sticky' sx={{
                backgroundColor: "white",
                boxShadow: "0 0 5px rgba(0,0,0,.7)",
                zIndex: 5,
                color: "black"
            }}>
            <Container maxWidth="lg" sx={{backgroundColor: "white"}}>
                <Toolbar>
                    {/* Desktop Menu */}
                    <Box sx={{flexGrow: 1, display: { xs: 'none', md: 'flex' }, flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                        <Box sx={{padding: "12px", display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer"}}>
                            <img alt="marsoc logo" src="/imgs/marsoc-logo.png" height={64}/>
                            <Typography pl={"12px"} fontWeight={"bold"} sx={{font: "Roboto", fontSize: "1.25rem"}}>IADT MARSOC</Typography>
                        </Box>
                        <Box gap={5} sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <NavButton buttonText={"Home"} linkTo={ROUTES.HOME} onThisPage={currentRoute.pathname === ROUTES.HOME}/>
                            {/* <NavButton buttonText={"News"} linkTo={ROUTES.NEWS} onThisPage={currentRoute.pathname === ROUTES.NEWS}/> */}
                            <NavButton buttonText={"Documents"} linkTo={ROUTES.DOCUMENTS} onThisPage={currentRoute.pathname === ROUTES.DOCUMENTS}/>
                            <NavButton buttonText={"Join Us"} linkTo={ROUTES.JOIN_US} onThisPage={currentRoute.pathname === ROUTES.JOIN_US}/>
                            <NavButton buttonText={"Gallery"} linkTo={ROUTES.GALLERY} onThisPage={currentRoute.pathname === ROUTES.GALLERY}/>
                            <NavButton buttonText={"Members Area"} linkTo={ROUTES.MEMBERS_AREA} onThisPage={currentRoute.pathname === ROUTES.MEMBERS_AREA}/>
                            {session &&
                                <NavButton buttonText={"Sign Out"} onClick={() => {signOut()}}/>
                            }
                        </Box>
                    </Box>
                    {/* Mobile Menu */}
                    <Box sx={{flexGrow: 1, display: { xs: 'flex', md: 'none' }, flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                        <Box sx={{padding: "12px", display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer"}}>
                            <img alt="marsoc logo" src="/imgs/marsoc-logo.png" height={64}/>
                            <Typography pl={"12px"} fontWeight={"bold"} sx={{font: "Roboto", fontSize: "1.25rem"}}>IADT MARSOC</Typography>
                        </Box>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default NavBar;