import { Box, Typography } from '@mui/material';
import * as Roboto300 from '@fontsource/roboto/300.css';
// import { useMemo } from 'react';

function AboutUsSection (props) {

    // const isMobile = useMemo(() => window.innerWidth > 900, [])
    return (
        <Box sx={{
            objectPosition: "center top",
            minHeight: "calc(100vh - 88px)",
            backgroundColor: "white",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <Box  sx={{
                minHeight: "calc(80vh - 88px)",
                position: "relative",
                display: "flex",
                flexDirection: "row",
                alignItems: "space-between",
            }}>
                <Box sx={{display: { xs: 'none', md: 'flex' }, alignItems: "center"}}>
                    <img alt="promotionposter" src={"/imgs/promotion-poster.webp"} style={{
                        maxHeight: "703px"
                    }}/>
                </Box>
                <Box sx={{
                    backgroundColor: "rgb(255, 195, 20)",
                    flexDirection: "column",
                    alignItems: "space-evenly",
                    width: { xs: '80vw', md: "26vw" },
                    marginLeft: { xs: 'none', md: "1rem" },
                    padding: "4rem 3rem"
                }}>
                    <div style={{
                        maxHeight: "703px"
                    }}>
                    <Typography sx={{font: Roboto300, fontSize: "2rem", color: "white", fontWeight: "bold" }}>
                        ABOUT US
                    </Typography>
                    <br/>
                    <Typography sx={{font: Roboto300, fontSize: "1rem", color: "black" }}>
                    IADT MARSOC (Martial Arts Society) is a martial arts club/school within IADT that primarily is centred around Taekwon-do, Kickboxing and Self Defense.
                    <br/>
                    Started off in 2019, MARSOC is a very relaxed enviroment for members to train, socialize and have a bit of craic. We're serious when we need to be but otherwise we are always enjoying ourselves
                    <br/>
                    <br/>
                    We've represented not only IADT on a national level but we've also represented Ireland at an international level aswell.
                    <br/>
                    ​<br/>
                    <br/>
                    With so much to explore, we welcome everyone as there is bound to be something you'll enjoy with us as we encourage you to grow.

                    Our motto "Ná lig suas riamh, ar aghaidh!" (Never give up, forward!) is what we hope we will instill in you.
                    </Typography>
                    </div>
                </Box>
            </Box>
        </Box>
    )
}

export default AboutUsSection;