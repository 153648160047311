import './App.css';
import DocumentsPage from './modules/documents-page/DocumentsScreen';
import NavBar from './modules/global/NavBar/NavBar';
import JoinUsPage from './modules/join-us/JoinUsScreen';
import LandingPage from './modules/landing-page/LandingScreen';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from './modules/global/Auth/authManager';
import MembersAreaScreen from './modules/members-area/MembersAreaScreen';

function App(props) {
  
  return (
    <AuthProvider>
      <Router>
      <NavBar />
        <Routes>
          <Route path="/join-us" element={<JoinUsPage/>}/>
          <Route path="/documents" element={<DocumentsPage/>}/>
          <Route path="/members-area" element={<MembersAreaScreen/>}/>
          <Route exact path="/" element={<LandingPage/>}/>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
