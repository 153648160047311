import * as React from 'react';
import { useAuth } from '../global/Auth/authManager';
import LoginScreen from './login-screen/LoginScreen';

function MembersAreaScreen() {
    const { session } = useAuth();
    return (
        <>
            {!session &&
                <LoginScreen />
            }
            {session &&
                <>Logged In</>
            }
        </>
    );
}

export default MembersAreaScreen;