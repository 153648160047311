import { Box, Typography } from '@mui/material';
import * as Roboto300 from '@fontsource/roboto/300.css';
import DocumentFile from './document-file/DocumentFile';

function DocumentsPage (props) {
    return (
        <Box sx={{
            objectPosition: "center top",
            minHeight: "100vh",
            overflowY: "scroll",
            background: "#000 url('/imgs/join-the-team-background-large.png')",
            backgroundSize: "cover",
            position: "relative",
            zIndex: 0,
            display: "flex",
            flexDirection: "column"
        }}>
            <Box sx={{
                objectPosition: "center",
                minHeight: "calc(50vh - 88px)",
                background: "#000 url('/imgs/DocumentPageBanner.jpg') center center",
                backgroundSize: "cover",
                position: "relative"
            }}/>
            <Box sx={{
                minHeight: "calc(50vh - 22px)",
                background: "white",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "1rem",
                flexGrow: 1
            }}>
                <Typography sx={{font: Roboto300, fontSize: "3rem", cursor: "pointer", textAlign: "center"}}>Documents</Typography>
                <Typography sx={{font: Roboto300, fontSize: "1rem", cursor: "pointer", textAlign: "center"}}>These are documents you <b>must read</b> through prior to joining or signing a member registration form</Typography>
                <Box sx={{
                    display: "flex",
                    marginTop: "4rem",
                    flexDirection: "row",
                    maxWidth: "894px",
                    flexWrap: "wrap",
                    alignItems: "center",
                    flexGrow: 1
                }}>
                    <DocumentFile fileName={"Code of Conduct"} fileDescription={"Prior to starting with us you must read through the Code of Conduct (CoC), particularly up to section 7.0. Your registration form will specificaly ask if you have read it and will hold you as a member and student of MARSOC accountable."} />
                    <DocumentFile fileName={"Code of Conduct V2"} fileDescription={"Testing short text description."} />
                </Box>
            </Box>
        </Box>
    )
}

export default DocumentsPage;